export const PHONE_TYPE_MOBILE = "MOBILE";
export const PHONE_TYPE_HOME = "HOME";
export const PHONE_TYPE_OFFICE = "OFFICE";
export const PHONE_TYPE_BUSINESS = "BUSINESS";
export const PHONE_TYPE_FAX = "FAX";
export const PHONE_TYPE_PAGER = "PAGER";
export const PHONE_TYPE_OTHER = "OTHER";

export const phoneTypesOptions = {
  [PHONE_TYPE_MOBILE]: "MOBILE",
  [PHONE_TYPE_HOME]: "HOME",
  [PHONE_TYPE_OFFICE]: "OFFICE",
  [PHONE_TYPE_BUSINESS]: "BUSINESS",
  [PHONE_TYPE_FAX]: "FAX",
  [PHONE_TYPE_PAGER]: "PAGER",
  [PHONE_TYPE_OTHER]: "OTHER",
};

export const QUERY_ACTIONS_VIEW = "view";
export const QUERY_ACTIONS_EDIT = "edit";
export const QUERY_ACTIONS_ADD = "add";
export const QUERY_ACTIONS_EDIT_ESIGN_TRANSACTION = "edit_esign_transaction";

export const STATUS_DRAFT = "DRAFT";
export const STATUS_SENT = "SENT";
export const STATUS_COMPLETED = "COMPLETED";
export const STATUS_DECLINED = "DECLINED";
export const STATUS_EXPIRED = "EXPIRED";
export const STATUS_LIST = [
  '',
  STATUS_DRAFT,
  STATUS_SENT,
  STATUS_COMPLETED,
  STATUS_DECLINED,
  STATUS_EXPIRED
];
