<template>
    <div>
        <span class="icon">
            <svg
                class="svg-icon"
                :style="{ width: width, verticalAlign: 'middle', overflow: 'hidden' }"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M453.746221 292.680418c8.541218 0 17.082437-3.247442 23.599565-9.76457L702.442479 57.77467a33.364134 33.364134 0 1 0-47.154643-47.154644L430.146657 235.716719a33.40862 33.40862 0 0 0 23.599564 56.963699M858.3642 219.145866L634.335159 443.152664l-88.882054-88.904297L769.504389 130.263812c23.532836-23.599564 65.260247-23.599564 88.859811 0a62.947 62.947 0 0 1 0 88.904297zM288.148901 789.338922l-119.221173 30.339119 30.361362-119.221173 298.987129-298.987129 88.882054 88.859811L288.148901 789.338922zM905.563329 83.064683a128.71883 128.71883 0 0 0-91.640156-37.812685 128.674345 128.674345 0 0 0-91.617913 37.812685L145.617319 659.752624c-4.226124 4.226124-7.273381 9.5199-8.741403 15.347502L90.410799 857.624183a33.40862 33.40862 0 0 0 40.570787 40.570788l182.501815-46.465118c5.827602-1.490265 11.121378-4.51528 15.369744-8.741403l576.710184-576.687941a129.76424 129.76424 0 0 0 0-183.235826z"
            />
            <path
                d="M331.789189 1024.378127c-115.88476 0-189.730711-49.579104-194.49066-52.871032A33.364134 33.364134 0 0 1 175.088971 916.500759c6.383671 4.315095 154.587156 101.271269 362.245528-18.795129 206.635205-119.465844 395.320506 9.853541 403.261171 15.436473a33.364134 33.364134 0 1 1-38.346512 54.605967c-6.450399-4.470794-162.37212-110.101643-331.506039-12.278002C480.504257 1007.651574 399.006798 1024.378127 331.789189 1024.378127"
                fill="#FA4E4E"
            />
            </svg>
        </span>
    </div>
</template>
<script>
export default {
  name: "EsignIcon",
  props: {
    width: {
      type: String,
      default: '48px',
    },
  }
};
</script>