<template>
  <div class="wrapper">
    <div class="elite-content_body_inner_header">
      <div class="elite-content_body_inner_header_left">
        <i class="far fa-home"></i>
        <h1>{{ $t("APPS.ESIGN_MODULE") }}</h1>
      </div>
      <div class="elite-content_body_inner_header_right">
        <button><i class="far fa-ellipsis-h"></i></button>
      </div>
    </div>
    <div class="elite-content_body_inner_container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  mixins: [moduleLayoutMixin],
};
</script>
