import permissions from "../constants/permissions";
import EsignModuleLayout from "@/views/Pages/EsignModule/EsignModuleLayout.vue";
import EsignModuleMenu from "@/views/Pages/EsignModule/EsignModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListEsignPage = () =>
  import("@/views/Pages/EsignModule/EsignManagement/ListEsignPage.vue");

const EsignPage = () => import("@/views/Pages/EsignModule/EsignPage.vue");

let esignManagementPages = {
  path: "esigns",
  components: {
    default: EsignModuleLayout,
    SidebarSecondLevelMenu: EsignModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Esigns",
      components: { default: ListEsignPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CONTACTS,
        hasLevel2: true,
      },
    },
  ],
};

const esignModuleRoutes = [
  {
    path: "",
    components: {
      default: EsignModuleLayout,
      SidebarSecondLevelMenu: EsignModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Esign Page",
        components: { default: EsignPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  esignManagementPages,
];

export default esignModuleRoutes;
