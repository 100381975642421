export default {
  ESIGNS_LIST: "Liste des e-signs",
  ADD_ESIGN: "Ajouter un e-sign",
  ESIGNS: "E-signs",
  ESIGN_ADDED: "E-sign ajouté",
  EDIT_ESIGN: "Modifier un e-sign",
  ESIGN_UPDATED: "E-sign mise à jour",
  DELETE_THIS_ESIGN: "Supprimer ce e-sign ?",
  ESIGN_DELETED: "E-sign supprimé",
  ESIGN_TYPE: "Type de e-sign",
  VIEW_ESIGN: "E-sign",
  PROPERTIES: "Propriétés",
  WITHOUT_ADDRESS: "Ne pas mettre d’adresse",
  RETURN_TO_FROM: "Retourner au formulaire",
  DRAFT: "Brouillon",
  SENT: "Envoyé",
  COMPLETED: "Complété",
  DECLINED: "Refusé",
  EXPIRED: "Expiré",
  EMAIL_MESSAGE: "Message à tous les destinataires (facultatif)",
  EDIT_TRANSACTION: "Gérer",
};
