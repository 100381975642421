import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/esigns`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  // Vérifier si l'id contient le flag include_edition_url
  let esignId = id;
  let includeParam = 'include=organization';
  if (esignId.includes('include_edition_url')) {
    includeParam += '&include_edition_url=1';
    esignId = esignId.replace('_include_edition_url', '');
  }

  return axios
    .get(`${url}/esigns/${esignId}?${includeParam}`, options)
    .then((response) => {
      let customer = jsona.deserialize(response.data);
      delete customer.links;
      return customer;
    });
}


function add(esign) {
  const payload = jsona.serialize({
    stuff: esign,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/esigns?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(esign) {
  const payload = jsona.serialize({
    stuff: esign,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/esigns/${esign.id}?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/esigns/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
